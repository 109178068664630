<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <EditImages v-if="!!userId" owner-type="user" :owner-id="userId" />
      </div>
    </div>
  </div>
</template>

<script>
import EditImages from "@/components/Common/EditImages";

export default {
  name: "UserImages",
  components: { EditImages },
  computed: {
    userId() {
      return this.$route.params.user;
    },
  },
};
</script>
